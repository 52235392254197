var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1734114271404"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn:
    'https://1b7606f768c28f3743e7cda26804f043@o4506701350764544.ingest.sentry.io/4506701388382208',
  environment: process.env.AUTH_ENV,
  tracesSampleRate: 0.1,
  debug: false,
  integrations: [Sentry.browserTracingIntegration()],
});
